@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url('../images/v2/fonts/Campton-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url('../images/v2/fonts/Campton-Book.otf') format('opentype');
}
@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url('../images/v2/fonts/Campton-Thin.otf') format('opentype');
}
@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url('../images/v2/fonts/Campton-Bold.otf') format('opentype');
}
