:root {
  --ion-color-base: red;

  --ion-color-primary: #5e34b2;
  --ion-color-primary-rgb: 94, 52, 178;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1f9487;
  --ion-color-primary-tint: #39b1a3;

  --ion-color-secondary: #9276cb;
  --ion-color-secondary-rgb: 146, 118, 203;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #282764;
  --ion-color-secondary-tint: #434180;

  --ion-color-tertiary: #98d0d7;
  --ion-color-tertiary-rgb: 152, 208, 215;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #282764;
  --ion-color-tertiary-tint: #434180;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #f24965;
  --ion-color-danger-rgb: 242, 73, 101;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d54059;
  --ion-color-danger-tint: #f35b74;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-border-color: rgb(0, 0, 0, 0.13);

  --color-primary-light1: #8667c5;
  --color-primary-light2: #ad99d8;
  --color-primary-light3: #d7ccec;

  --color-secondary-light1: #a088d2;
  --color-secondary-light2: #c0b0e1;
  --color-secondary-light3: #e0d8f0;

  --color-tertiary-light1: #b3dce2;
  --color-tertiary-light2: #cbe7eb;
  --color-tertiary-light3: #e5f3f4;

  /* Purples */
  --accent-color1: #06234f;
  --accent-color2: #294268;
  /* Greens */
  --accent-color3: #00899a;
  --accent-color4: #00a4ae;
  --accent-color5: #bfe1e6;
  /* Grey */
  --accent-color6: #f1eff4;
  /* Pink */
  --accent-color7: #ff5cc9;
  --accent-color8: #ecadcd;
}
